<template>
  <div>
    <div
      :class="
        `drop-zone text-center ${dragging ? 'drop-active' : ''} ${
          error ? 'drop-error' : ''
        }`
      "
      @dragover.prevent="handleDragOver"
      @dragleave.prevent="handleDragLeave"
      @drop.prevent="handleDrop"
    >
      <slot name="header"></slot>
      <div class="drop-zone-detail">
        <div class="mb-1 title">
          <slot name="title"> Upload File </slot>
        </div>
        <v-btn
          class="mb-1 text-capitalize"
          @click="$refs.file.click()"
          large
          color="primary"
          >Choose a File</v-btn
        >
        <br />
        <div>
          Or<br />
          Drag it here
        </div>
        <div v-if="file">
          <v-divider class="mt-1 mb-1"></v-divider>
          <div class="text-left">Selected File:</div>
          <v-icon>mdi-file-document-outline</v-icon> {{ file.name }}
          <v-btn color="error" icon @click="emitFile(null)"
            ><v-icon>mdi-trash-can</v-icon></v-btn
          >
        </div>
        <slot name="footer"></slot>
        <input
          type="file"
          class="d-none"
          ref="file"
          @change="emitFile($event.target.files[0])"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseFileUpload',
  props: ['file', 'error'],
  data: () => ({
    dragging: false
  }),
  methods: {
    emitFile(file) {
      if (file) {
        this.$emit('update:error', false)
      } else {
        this.$refs.file.value = null
      }
      this.$emit('update:file', file)
    },
    handleDragOver() {
      this.dragging = true
    },
    handleDragLeave() {
      this.dragging = false
    },
    handleDrop(e) {
      if (e.dataTransfer.items) {
        this.emitFile(e.dataTransfer.files[0])
      }
      this.dragging = false
    }
  }
}
</script>

<style scoped>
.drop-zone {
  /* z-index: 999; */
  width: 100%;
  border: 2px dashed rgb(197, 197, 197);
  background-color: rgb(250, 250, 252);
  padding: 1.5em;
  border-radius: 10px;
  z-index: 1000;
}
.drop-active {
  border: 2px solid rgb(16, 131, 112);
  background-color: rgb(234, 240, 232);
}
.drop-error {
  border-color: rgb(235, 43, 43);
}
</style>
